<template>
  <div class="flex flex-col gap-2">
    <ArrowButton background="#ccc" color="#000" text="trabajos" @onClick="goToTrabajos" />
    <ArrowButton
      background="#ccc"
      color="#000"
      text="trabajos del dia"
      @onClick="goToTrabajosDelDia"
    />
    <ArrowButton background="#ccc" color="#000" text="rankings" @onClick="goToRankings" />
  </div>
</template>

<script>
import ArrowButton from '@/components/shared/ArrowButton.vue'
export default {
  name: 'Recurso',
  components: { ArrowButton },
  methods: {
    goToTrabajos() {
      this.$router.push({ name: 'Trabajos' })
    },
    goToTrabajosDelDia() {
      this.$router.push({ name: 'TrabajosDelDia' })
    },
    goToRankings() {
      this.$router.push({ name: 'Rankings' })
    }
  }
}
</script>
